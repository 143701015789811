@import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=auto');
@import url('./DoodleCSS/doodle.css');

:root {
    --paper-color: #F1EDE9;
    --paper-line: #94ACD4;
    --break-point: 775px;
}

body {
    font-family: 'Short Stack', cursive;
    padding: 5% 0 10% 0;
}

.notepad {
    width: 80%;
    max-width: 800px;
    /* box-shadow: 10px 10px 40px rgba(0, 0, 0, .15); */
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));
    border-radius: 0 0 10px 10px;
    /* overflow: hidden; */
    margin: 0 auto;
    position: relative;
}

header {
    width: 100%;
    background: #333;
    border-radius: 5px 5px 0 0;
    color: #fff;
    padding: 3%;
    display: grid;
    grid-template-columns: auto minmax(auto, 78px);
    align-items: end;
}

@media(max-width: 576px) { 
    header {
        grid-gap: 10px 20px;
        grid-template-columns: auto;
        text-align: center;
    }
}

header > h1 {
    margin: 0;
    margin-block-end: 0.4em;
    word-break: break-word;
}

@media(max-width: 775px) {
    header > h1 {
        font-size: 1.5em;
        margin-block-end: 0;
        line-height: 1;
        margin-bottom: -3px;
    }
 }

 @media(max-width: 576px) { 
    header > h1 {
        width: 60%;
        margin-inline: auto;
        margin-block-start: 10px;
    }
}

.add-item-controls {
    grid-row: 2;
    display: flex;
    align-items: stretch;
}

.add-item-controls > .add-btn, .add-item-controls > .add-item-input {
    color: #fff;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-image: url('../images/button.svg') 10 10 10 10 stretch stretch;
}

@media(max-width: 576px) { 
    .add-item-controls {
        display: flex;
        flex-wrap: wrap;
    }
}

.add-item-controls input[type='text'] {
    width: 60%;
    margin-inline-end: 3%;
}

@media(max-width: 576px) {
    .add-item-controls input[type='text'] {
        flex-basis: 100%;
        margin-inline-end: 0;
        margin-block-end: 0.4em;
    }
}

.add-item-controls input[type='text']:focus {
    outline: none;
}

.add-btn {
    cursor: pointer;
    font-weight: bold;
}

@media(max-width: 576px) {
    .add-btn {
        flex-basis: 100%;
    }
}

.top-icon {
    grid-row-end: span 2;
    text-align: right;
    display: flex;
    align-items: flex-end;
}

@media(max-width: 576px) {
    .top-icon {
        display: none;
    }
}

.top-icon > .tree-icon {
    width: 100%;
    height: auto;
}

.top-icon > .tree-icon .star {
    color: #ddd700;
}

.top-icon > .tree-icon .tree {
    color: green;
}

.list {
    width: 100%;
    height: 100%;
    /* min-height: 60vh; */
    min-height: 454px;
    padding: 35px 20px;
    background: repeating-linear-gradient(var(--paper-color), var(--paper-color) 31px, var(--paper-line) 31px, var(--paper-line) 32px);
    line-height: 32px;
    line-height: 32px;
    outline: 0;
    font-size: 22px;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 29px;
}

@media(max-width: 576px) {
    .list {
        grid-template-rows: 1fr 131px;
        font-size: 17px;
    }
}

.empty-list {
    margin: 0;
    line-height: 32px;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    grid-template-rows: 320px 1fr;
}

.empty-list p {
    margin: 0;
}

.empty-list .truck-icon {
    width: 240px;
    height: auto;
    margin-top: -5px;
}

@media(max-width: 576px) {
    .empty-list {
        grid-template-rows: 288px 1fr;
    }

    .empty-list .truck-icon {
        width: 187px;
    }
}

.wish-list {
    margin: 0 auto;
    padding: 0;
}

.wish-list li {
    padding-inline-start: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    grid-gap: 20px;
}

@media(max-width: 576px) {
    .wish-list li {
        /* font-size: 0.8em; */
        grid-gap: 10px;
        padding-inline-start: 20px;
    }
}

.wish-list li:before {
    content: "*";
    position: absolute;
    left: 10px;
    top: 6px;
    width: 14px;
    height: 14px;
    line-height: 1;
}

@media(max-width: 576px) {
    .wish-list li:before {
        top: 8px;
        left: 0;
    }
}

.wish-list .item-text {
    display: inline-flex;
    /* flex: 1 1; */
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
}

.wish-list .item-text > .checkmark-icon {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    margin-left: 30px;
}

@media(max-width: 576px) {
    .wish-list .item-text > .checkmark-icon {
        margin-left: 15px;
        width: 22px;
        height: 22px;
        margin-top: 1px;
    }
}

.wish-list .completed {
    text-decoration: line-through;
}

.wish-list .remove-item {
    width: 24px;
    height: 24px;
}

@media(max-width: 576px) {
    .wish-list .remove-item {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
}

.wish-list .remove-item .remove-icon {
    width: 100%;
    height: 100%;
    fill: maroon;
}

footer {
    display: grid;
    line-height: 1;
    grid-template-columns: 1fr auto;
    align-items: end;
}

footer > .grid-cell.icon {
    text-align: right;
}

footer .remove-items-btn {
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: -0.5px;
    cursor: pointer;
    margin-bottom: 4px;
    width: 170px;
    line-height: 1;
    text-align: center;
}

footer .present-icon {
    width: 80px;
    height: 64px;
    overflow: hidden;
}

@media(max-width: 576px) {
    footer {
        grid-template-rows: 64px 37px;
        grid-gap: 30px;
        align-items: inherit;
        grid-template-columns: auto;
    }

    footer > .grid-cell:nth-child(1) {
        grid-row: 2;
    }

    footer .remove-items-btn {
        width: 100%;
        height: 37px;
        margin: 0;
    }

    footer > .grid-cell:nth-child(2) {
        text-align: right;
    }

    .empty-list footer {
        grid-template-rows: 64px;
        grid-gap: 0;
    }

    .empty-list footer > .grid-cell:nth-child(1) {
        grid-row: 1;
    }
}