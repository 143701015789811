/* Doodle CSS
 * https://github.com/chr15m/doodle-css
 */

.doodle * {
  box-sizing: border-box;
}

.doodle {
  background-color: #FDF7F1;
  color: #3c3c3c;
}

.doodle input, .doodle textarea, .doodle table, .doodle img {
  max-width: 100%;
}

.doodle fieldset * {
  max-width: calc(100vw - 100px);
}

.doodle table {
  overflow: scroll;
}

.doodle a {
  color: #1c1c1c;
  font-weight: bold;
}

.doodle ul li {
  list-style-type: "* ";
}

.doodle label {
  padding: 0.25em 0px;
  display: inline-block;
}

.doodle fieldset, .doodle textarea {
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-image: url(border.svg) 10 10 10 10 stretch stretch;
}

.doodle .border, .doodle-border {
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-image: url(button.svg) 10 10 10 10 stretch stretch;
}

.doodle button, .doodle input, .doodle select {
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-image: url(button.svg) 10 10 10 10 stretch stretch;
}

.doodle button {
  text-align: center;
}

.doodle input[type="color"] {
  min-height: 1.5em;    
}

.doodle hr {
  border-style: solid;
  border-width: 0px 3px 6px 3px;
  border-image: url(hr.svg) 0 3 6 3 stretch stretch;
}

.doodle blockquote {
  border-left-style: solid;
  border-width: 0px 6px 3px 6px;
  border-image: url(bq.svg) 0 6 3 6 stretch stretch;
  padding-left: 0.5em;
  border-right-width: 0px;
}

.doodle pre {
  overflow: auto;
}

.doodle table {
  border-collapse: collapse;
  overflow: auto;
  display: block;
}

.doodle table thead {
  border-bottom: 3px solid #bbb;
}

.doodle table caption {
  border-bottom-style: solid;
  border-width: 0px 3px 6px 3px;
  border-image: url(hr.svg) 0 3 6 3 stretch stretch;
}

.doodle table td {
  vertical-align: top;
}

.doodle img {
  border-radius: 3px;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-image: url(button.svg) 3 3 3 3 stretch stretch;
}

.doodle select {
  -webkit-appearance: none;
  appearance: none;
  padding-right: 1.5em;
  background: url(caret.svg) no-repeat right transparent;
  background-position-x: calc(100% - 10px);
}

.doodle select[multiple] {
  background: none;
  padding: 0px;
}

.doodle button, .doodle textarea, .doodle input, .doodle select {
  background-color: unset;
  font-family: 'Short Stack', cursive;
  font-size: 1em;
  color: #3c3c3c;
}

.doodle input[type="checkbox"] {
  border-width: 6px 6px 6px 6px;
  border-image: url(checkbox.svg) 6 6 6 6 stretch stretch;
}

.doodle input[type="radio"] {
  border-width: 16px 16px 16px 16px;
  border-image: url(radio.svg) 16 16 16 16 stretch stretch;
}

.doodle input[type="checkbox"], .doodle input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background: transparent;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.doodle input[type="checkbox"]:after, .doodle input[type="radio"]:after {
  font-size: 1.25em;
  content: " ";
  margin-bottom: -0.25em;
}

.doodle input[type="checkbox"]:disabled:after, .doodle input[type="radio"]:disabled:after {
  content: "-";
  color: grey;
}

.doodle input[type="checkbox"]:checked:after, .doodle input[type="radio"]:checked:after {
  content: "*";
}
